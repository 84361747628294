<template>
  <div>
    <trev-xero-tenant-select v-model="selectedXeroTenantId"></trev-xero-tenant-select>
    <trev-xero-account-code-search v-show="selectedXeroTenantId" :tenantId="selectedXeroTenantId"
      v-model="selectedXeroAccountCode" :defaultCodeId="accountCodeId"></trev-xero-account-code-search>
  </div>
</template>

<script>
import TrevXeroAccountCodeSearch from "./trev-xero-account-code-search.vue";
import TrevXeroTenantSelect from "./trev-xero-tenant-select.vue";

export default {
  components: {
    TrevXeroAccountCodeSearch,
    TrevXeroTenantSelect,
  },
  props: {
    tenantId: {
      type: String,
      default: "",
    },
    accountCodeId: {
      type: String,
      default: "",
    },
  },
  watch: {
    selectedXeroTenantId() {
      this.$emit("update:tenantId", this.selectedXeroTenantId);
    },
    selectedXeroAccountCode() {
      console.log('selectedXeroAccountCode watcher ' + this.selectedXeroAccountCode.id);
      this.$emit("update:accountCodeId", this.selectedXeroAccountCode.id);
    },
    tenantId(newVal, old) {
      this.selectedXeroTenantId = newVal;
    },
    accountCodeId: {
      handler(newVal, old) {
        console.log("accountCodeId Watcher " + newVal)
        this.selectedXeroAccountCode.id = newVal;
      },
      immediate: true
    }
  },
  data() {
    return {
      selectedXeroTenantId: this.tenantId,
      selectedXeroAccountCode: {
        id: this.accountCodeId,
      },
    };
  },
};
</script>

<style>

</style>