<template>
  <v-row>
    <v-col cols="3">
      <v-card class="" elevation="2">
        <v-card-title> Add New Tag </v-card-title>
        <v-card-text>
          <v-form class="mt-6">
            <v-text-field label="Meta Tag" v-model="newTag" @keydown.enter.prevent="addTag"> </v-text-field>
            <v-btn color="info" @click="addTag" block> Add Meta Tag </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="9">
      <v-list class="" elevation="2">
        <v-list-item class="mb-2">
          <v-list-item-content>
            <v-list-item-title>All Tags </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="allTags && allTags.length == 0">
          <v-list-item-content>
            <v-list-item-title> No Tags Added </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-for="(tag, index) in allTags" :key="index">
          <v-list-item-content>
            <v-list-item-title>
              <v-divider></v-divider>
            </v-list-item-title>
            <v-list-item-title
              class="mt-4 text-wrap"
              v-if="tag.value.toString().includes('ZohoCategories')"
            >
              <v-chip label color="info" class="mt-1"> Zoho Categories </v-chip>
              <v-chip
                class="ml-2 mt-1"
                v-for="(jValue, index) in getJSONValue(tag.value)
                  .ZohoCategories"
                :key="index"
                close
                @click:close="removeValueFromZoho(tag, jValue)"
              >
                {{ jValue }}
              </v-chip>
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    class="mt-1 ml-2"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="getTicketStatuses(tag)"
                    :loading="isLoadingStatus"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <v-list dense style="max-height: 400px" class="overflow-y-auto">
                  <v-list-item>
                    <v-list-item-title>
                      <v-text-field dense @click.stop placeholder="Custom" v-model="customTag" @keydown.enter="addZohoCategoryToTag(tag, customTag)">
                      </v-text-field>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-for="(item, i) in ticketStatuses"
                    :key="i"
                    @click="addZohoCategoryToTag(tag, item)"
                  >
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn
                color="danger"
                class="white--text float-right mt-1"
                text
                @click="removeTag(tag)"
              >
                Remove
              </v-btn>
              <v-btn
                color="primary"
                class="white--text float-right mt-1"
                text
                v-if="editingIds.some((x) => x.id === tag.id)"
                @click="saveMetaTag(tag)"
                :loading="isSaving"
              >
                Save
              </v-btn>
            </v-list-item-title>
            <v-list-item-title class="mt-4" v-else>
              <v-chip>
                {{ tag.value }}
              </v-chip>
              <v-btn
                color="danger"
                class="white--text float-right"
                text
                @click="removeTag(tag)"
              >
                Remove
              </v-btn>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    newTag: "",
    allTags: [],
    editingIds: [],
    ticketStatuses: [],
    isLoadingStatus: false,
    isSaving: false,
    customTag: ''
  }),
  watch: {
    tags: {
      deep: true,
      immediate: true,
      handler(newValue, oldValue) {
        this.allTags = this.tags;
        this.newTag = "";
      },
    },
  },
  methods: {
    removeValueFromZoho(tag, jValue) {
      var oldTag = JSON.parse(JSON.stringify(tag));
      var fullObject = this.getJSONValue(tag.value);
      fullObject.ZohoCategories = fullObject.ZohoCategories.filter(
        (x) => x != jValue
      );

      this.tags.filter((x) => x.id == tag.id)[0].value = JSON.stringify(
        fullObject
      )
        .replaceAll("{", "")
        .replaceAll("}", "")
        .replaceAll('"ZohoCategories"', "ZohoCategories");

      if (!this.editingIds.some((x) => x.id == tag.id)) {
        this.editingIds.push({ id: tag.id, oldTag: oldTag, newTag: tag });
      }

      var editingTag = this.editingIds.filter((x) => x.id === tag.id)[0];
      if (
        editingTag &&
        JSON.parse(
          "{" +
            editingTag.oldTag.value.replaceAll(
              "ZohoCategories",
              '"ZohoCategories"'
            ) +
            "}"
        )
          .ZohoCategories.sort()
          .join(",") ===
          JSON.parse(
            "{" +
              editingTag.newTag.value.replaceAll(
                "ZohoCategories",
                '"ZohoCategories"'
              ) +
              "}"
          )
            .ZohoCategories.sort()
            .join(",")
      ) {
        //both values are same, no longer editing
        this.editingIds = this.editingIds.filter((x) => x.id != tag.id);
      } else {
        if (!this.editingIds.some((x) => x.id == id)) {
          this.editingIds.push({ id: tag.id, oldTag: oldTag, newTag: tag });
        }
      }

      this.$emit("update:tags", this.allTags);
    },
    addZohoCategoryToTag(tag, cat) {
      var oldTag = JSON.parse(JSON.stringify(tag));
      var fullObject = this.getJSONValue(tag.value);
      fullObject.ZohoCategories.push(cat);

      this.tags.filter((x) => x.id == tag.id)[0].value = JSON.stringify(
        fullObject
      )
        .replaceAll("{", "")
        .replaceAll("}", "")
        .replaceAll('"ZohoCategories"', "ZohoCategories");

      var editingTag = this.editingIds.filter((x) => x.id === tag.id)[0];
      if (
        editingTag &&
        JSON.parse(
          "{" +
            editingTag.oldTag.value.replaceAll(
              "ZohoCategories",
              '"ZohoCategories"'
            ) +
            "}"
        )
          .ZohoCategories.sort()
          .join(",") ===
          JSON.parse(
            "{" +
              editingTag.newTag.value.replaceAll(
                "ZohoCategories",
                '"ZohoCategories"'
              ) +
              "}"
          )
            .ZohoCategories.sort()
            .join(",")
      ) {
        //both values are same, no longer editing
        this.editingIds = this.editingIds.filter((x) => x.id != tag.id);
      } else {
        if (!this.editingIds.some((x) => x.id == id)) {
          this.editingIds.push({ id: tag.id, oldTag: oldTag, newTag: tag });
        }
      }

      this.$emit("update:tags", this.allTags);
    },
    saveMetaTag(tag) {
      this.isSaving = true;
      this.$courier.MetaData
        .updateById(tag.id, tag)
        .then(() => {
          //idk show saved?
          this.editingIds = this.editingIds.filter((x) => x.id != tag.id);
          this.isSaving = false;

          this.$emit("update:tags", this.allTags);
        });
    },
    getJSONValue(tagValue) {
      var anus =
        "{" + tagValue.replaceAll("ZohoCategories", '"ZohoCategories"') + "}";
      return JSON.parse(anus);
    },
    isJSONValue(tagValue) {
      try {
        JSON.parse(tagValue);
        return true;
      } catch (e) {
        return false;
      }
    },
    addTag() {
      this.allTags.push({ value: this.newTag });
      this.newTag = "";

      this.$emit("update:tags", this.allTags);
    },
    removeTag(tag) {
      this.allTags = this.allTags.filter((t) => t != tag);
      this.$emit("update:tags", this.allTags);
    },
    async getTicketStatuses(tag) {
      console.log(tag);
      this.isLoadingStatus = true;
      this.ticketStatuses = [];
      this.$courier.ZohoTickets.GetCategories().then((x) => {
        var fullObject = this.getJSONValue(tag.value);

        x.payload.forEach((status) => {
          if (!fullObject.ZohoCategories.includes(status)) {
            this.ticketStatuses.push(status);
          }
        });
      });

      this.isLoadingStatus = false;
    },
  },
};
</script>

<style>
</style>