<template>
  <v-autocomplete
    label="Xero Account Code"
    :items="accountCodes"
    :loading="isLoading"
    :search-input.sync="search"
    open-on-clear
    clearable
    return-object
    no-filter
    :auto-select-first="false"
    v-model="selectedAccountCode"
    item-text="formatName"
    item-value="id"
  >
    <!-- <template v-slot:selection="data">
    {{data.item.code}} <span v-show="!!data.item.code && !!data.item.name"> - </span>{{data.item.name}}
  </template> -->
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title
          >{{ data.item.code }}
          <span v-show="!!data.item.code && !!data.item.accountType"> - </span>
          {{ data.item.accountType }}</v-list-item-title
        >
        <v-list-item-subtitle
          >{{ data.item.name }}
          <span v-show="!!data.item.description && !!data.item.name">-</span>
          {{ data.item.description }}</v-list-item-subtitle
        >
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    tenantId: {
      type: String,
      default: "",
    },
    value: {
      type: Object,
      default: () => {},
    },
    defaultCodeId: {
      type: String,
      default: "",
    },
  },
  created() {},
  watch: {
    tenantId: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.accountCodes = [];
          this.getAccounts();
        }
      },
    },
    defaultCodeId: {
      immediate: true,
      handler() {
        this.getDefault();
      },
    },
    selectedAccountCode(newVal, oldValue) {
      if (newVal) {
        this.$emit("input", newVal);
      }
    },
    search(newVal, old) {
      if (newVal) {
        if (
          this.selectedAccountCode &&
          newVal != this.selectedAccountCode.formatName
        ) {
          this.accountCodes = [];
          this.runSearch();
        } else if (!this.selectedAccountCode) {
          this.accountCodes = [];
          this.runSearch();
        }
      }
    },
  },
  methods: {
    runSearch() {
      clearTimeout(this.debounceId);
      this.debounceId = setTimeout(() => {
        if (!this.isLoading) {
          this.isLoading = true;
          if (this.tenantId) {
            this.getAccounts().then(() => {
              this.isLoading = false;
            });
          }
        }
      }, 200);
    },
    formatName(item) {
      return item.code + " - " + item.name;
    },
    getDefault() {
      if (this.defaultCodeId && this.tenantId != "" && this.defaultCodeId !== '00000000-0000-0000-0000-000000000000') {
        this.$courier.XeroAccountExternal.getById(this.defaultCodeId, [
          {
            key: "tenantId",
            value: this.tenantId,
          },
        ]).then((acc) => {
          acc.formatName =
            (!!acc.code ? acc.code : "") +
            (!!acc.code && !!acc.name ? " - " : "") +
            (!!acc.name ? acc.name : "");
          this.selectedAccountCode = acc;
          this.accountCodes.push(this.selectedAccountCode);
        });
      }
    },
    async getAccounts() {
      var accountCodePayload = await this.$courier.XeroAccountExternal.getData(
        1,
        25,
        this.search,
        null,
        null,
        [
          {
            key: "tenantId",
            value: this.tenantId,
          },
        ]
      );

      if (accountCodePayload && accountCodePayload.payload) {
        for (const acc of accountCodePayload.payload) {
          acc.formatName =
            (!!acc.code ? acc.code : "") +
            (!!acc.code && !!acc.name ? " - " : "") +
            (!!acc.name ? acc.name : "");

          this.accountCodes.push(acc);
        }

        // if (this.selectedAccountCode && this.selectedAccountCode.code) {
        //   this.accountCodes.push(this.selectedAccountCode);
        // }
      }
    },
  },
  data() {
    return {
      selectedAccountCode: {},
      search: "",
      isLoading: false,
      accountCodes: [],
      debounceId: "",
    };
  },
};
</script>

<style>
</style>